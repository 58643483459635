import Compressor from 'compressorjs'

import { api } from '../config'

export const getIPData = async () => {
  const res = await api.get('https://geolocation-db.com/json/')
  console.log(res.data)
}

export const bytesToSize = (bytes: number) => {
  var k = 1000
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) {
    return '0 Bytes'
  }
  // @ts-ignore
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)), 10)
  return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i]
}

export const windowScrollToTop = (value: number = 0) => {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'
  }
  window.requestAnimationFrame(() => {
    window.scrollTo({
      top: value,
      behavior: 'smooth',
    })
  })
}

export const base64ToFile = (base64: string, filename: string) => {
  const base64Arr = base64.split(',')
  // @ts-ignore
  const mimeType = base64Arr[0].match(/:(.*?);/)[1]
  const bStr = atob(base64Arr[1])
  let n = bStr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bStr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mimeType })
}

export const getNormalizedFile = (file: File) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: 1000,
      maxHeight: 1000,
      success(normalizedFile) {
        resolve(normalizedFile)
      },
      error(error) {
        reject(error)
      },
    })
  })
}

export const getImageQueryDimensionsFromUrl = (url: string) => {
  const [src, dimensionsQuery = ''] = url.split('?')
  const [widthQuery = '', heightQuery = ''] = dimensionsQuery.split('&')
  const width = widthQuery.split('=')[1]
  const height = heightQuery.split('=')[1]

  return { src, dimensionsQuery, width, height }
}

export const getImagesSrcWithDimensions = async (imagesFileArray: File[]) => {
  const srcArray: string[] = []
  await Promise.all(
    imagesFileArray.map(async (file: File) => {
      return new Promise((resolve, reject) => {
        const newImg = new Image()
        const imagUrl = URL.createObjectURL(file)

        newImg.onload = function () {
          const height = newImg.height
          const width = newImg.width
          srcArray.push(`${imagUrl}?w=${width}&h=${height}`)
          resolve(true)
        }

        newImg.onerror = function () {
          reject()
        }
        newImg.src = imagUrl
      })
    })
  )

  return srcArray
}

export const getFileExtension = async (blob: Blob) => {
  let temp = blob?.type?.split('/')?.at(-1)

  if (temp === 'jpeg') {
    temp = 'jpg'
  }

  return temp
}

export const getFileExtensionFromName = (file: File) => {
  let temp = file?.name?.split('.')?.at(-1)

  if (temp === 'jpeg') {
    temp = 'jpg'
  }

  return temp
}

export const adjustHexColor = (
  hex: string | undefined,
  amount: number
): string => {
  if (!hex) {
    return ''
  }

  hex = hex.replace('#', '')

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  const adjust = (channel: number) =>
    Math.min(255, Math.max(0, channel + amount))

  return `#${adjust(r).toString(16).padStart(2, '0')}${adjust(g)
    .toString(16)
    .padStart(2, '0')}${adjust(b).toString(16).padStart(2, '0')}`
}
