const ACTIONS = {
  JOIN: 'join',
  LEAVE: 'leave',
  SHARE_ROOMS: 'share-rooms',
  ADD_PEER: 'add-peer',
  REMOVE_PEER: 'remove-peer',
  RELAY_SDP: 'relay-sdp',
  RELAY_ICE: 'relay-ice',
  ICE_CANDIDATE: 'ice-candidate',
  SESSION_DESCRIPTION: 'session-description',

  ON_CONNECT: 'onConnect',
  NEW_USER: 'newUser',
  NEW_USER_RESPONSE: 'newUserResponse',
  DISCONNECT_USER: 'disconnectUser',
  DISCONNECT_USER_RESPONSE: 'disconnectUserResponse',
  DISCONNECT: 'disconnect',
  GET_USERS: 'getUsers',
}

module.exports = ACTIONS
