import React, { FC, useEffect, useState } from 'react'

import cn from 'classnames'

import { useStore } from 'store'

import styles from './styles.module.scss'

interface IProps {}

interface IActivationData {
  BackedAmountLeft: number
  BackersLeft: number
  IsTwitterConnected: boolean
  PostsLeft: number
}

const DefaultActivationData = {
  BackedAmountLeft: 0,
  BackersLeft: 0,
  IsTwitterConnected: true,
  PostsLeft: 0,
}

export const DeactivatedUserMode: FC<IProps> = props => {
  const { profile } = useStore()
  const [activationInfo, setActivationInfo] = useState(DefaultActivationData)

  useEffect(() => {
    getInfoForActivation()
  }, [])

  const getInfoForActivation = async () => {
    try {
      const activationInfo: IActivationData =
        await profile.getInfoForActivation(profile.viewedUser.Id)
      setActivationInfo(activationInfo)
    } catch (e) {
      console.log('getInfoForActivation', e)
    }
  }
  return (
    <div className={styles.deactivatedUser}>
      <div className={styles.title}>Your profile isn't Activated:</div>
      <div
        className={cn({
          [styles.deactivated]: activationInfo.PostsLeft !== 0,
        })}
      >
        ● Made 1 post onto ethereum/polygon
      </div>
      <div
        className={cn({
          [styles.deactivated]: activationInfo.BackedAmountLeft !== 0,
        })}
      >
        ● At least 2000 AUTH of profile backing
      </div>
      <div
        className={cn({
          [styles.deactivated]: activationInfo.BackersLeft !== 0,
        })}
      >
        ● Has 7+ activated backers
      </div>
      <div
        className={cn({
          [styles.deactivated]: !activationInfo.IsTwitterConnected,
        })}
      >
        ● Twitter connected
      </div>
    </div>
  )
}
