const main_link = () => '/'
const twitter_callback = () => '/twitter_callback'
const twitter_auth = () => '/twitter_auth'
const user_link = () => '/user/:nickName'
const login_link = () => '/login/:nickName'
const auth_token_link = () => '/auth_token'
const FAQ_link = () => '/FAQ'
const single_post = () => '/post/:postId'
const hashtags_posts = () => '/hashtags/:tag'
const notifications_link = () => '/notifications'
const registration_link = () => '/registration'
const user_info_link = () => '/user/info/:nickName'
const marketplace_link = () => '/marketplace'
const video_feed_link = () => '/videos/*'
const user_videos_link = () => '/user_videos/:nickname'
const shorts_video_link = () => '/shorts/:shortId'
const shorts_link = () => '/shorts'
const history_link = () => '/history'
const my_videos_link = () => '/my_videos'
const watch_later_link = () => '/watch_later'
const liked_videos_link = () => '/liked_videos'
const single_video = () => '/video/:videoId'
const video_chat_link = () => '/video_chat'
const video_post_link = () => '/video_post'
const video_stream_link = () => '/video_stream/:nickname'
const versions_link = () => '/versions'
const live_feed_link = () => '/live_feed'
const top_link = () => '/top'
const twitter_sign_up_link = () => '/welcome'
const twitterinvite = () => '/twitterinvite'
const terms_of_service_link = () => '/terms_of_service'
const privacy_policy_link = () => '/privacy_policy'
const invitation_change_nickname = () => '/user/invitation/change_nickname'
const invitation_create_first_post = () => '/user/invitation/create_first_post'
const invitation_about_authencity = () => '/user/invitation/about_authencity'
const restore_account = () => '/user/restore_account'
const dashboard = () => '/dashboard'
const dashboard_active = () => '/dashboard/:activeDashboard'
const widget = () => '/widget/:key'
const widget_page = () => '/widget_page/:key'
const testSocketIO = () => '/socketIO'

export {
  main_link,
  twitter_callback,
  user_link,
  login_link,
  auth_token_link,
  FAQ_link,
  single_post,
  hashtags_posts,
  notifications_link,
  registration_link,
  user_info_link,
  marketplace_link,
  video_feed_link,
  shorts_video_link,
  shorts_link,
  history_link,
  my_videos_link,
  watch_later_link,
  liked_videos_link,
  single_video,
  video_chat_link,
  video_post_link,
  video_stream_link,
  versions_link,
  live_feed_link,
  top_link,
  twitter_sign_up_link,
  twitterinvite,
  terms_of_service_link,
  privacy_policy_link,
  user_videos_link,
  invitation_change_nickname,
  invitation_create_first_post,
  invitation_about_authencity,
  restore_account,
  dashboard,
  dashboard_active,
  twitter_auth,
  widget,
  widget_page,
  testSocketIO,
}
