import React from 'react'

import { useOnlineUsers } from 'utils/hooks'

export const SocketIOTest: React.FC = () => {
  const { users } = useOnlineUsers(false)

  return (
    <div>
      {users?.map((m, index) => (
        <div key={index}>{JSON.stringify(m)}</div>
      ))}

      {/* <button
        onClick={() => {
          const message = {
            text: 'text',
            usernameFrom: 'userInfo.username',
            usernameTo: 'receiverUsername',
            id: `${socket.id}${Math.random()}`,
            socketID: socket.id,
            createdDate: new Date().toISOString(),
          }

          socket.emit('message', message)
        }}
      >
        Send
      </button>

      {messages?.map((m, index) => (
        <div key={index}>{JSON.stringify(m)}</div>
      ))}*/}
    </div>
  )
}
