import cloneDeep from 'lodash/cloneDeep'
import { makeAutoObservable } from 'mobx'

import { adjustHexColor, api, defaultLoadPage, IWidgetTheme } from '../utils'

import { toast } from '../App'

import type { ILoadPage, IPublication } from '../models'

import { makePersistable } from 'mobx-persist-store'

import { WIDGET_DEMO_PUBLICATIONS } from '../helpers/widgetDemoPublications'
import PublicationStore from './publication'

class Widget {
  apiKey: string = ''
  parentURL: string = ''
  key: string = '' // external site URL
  name: string = '' // external site name
  pathname: string = '' // this is for restoring url
  theme: IWidgetTheme & { isCustom: boolean } = { isCustom: false }

  openedPostID: string = '' // the id of publication that was opened from Authencity. Used to show this publication in widget on the external website

  publicationsCount: number = 0 // how many publications to load in one request

  publications: IPublication[] = []

  scrollPositionPublications: number = 0
  scrollPositionPublicationsWindow: number = 0

  pageSettingsPublications: ILoadPage = defaultLoadPage
  startTotalItemsPublications: number = 0
  totalCommentsCount: number = 0

  isWidget = false // if Authencity is run in widget iFrame
  isWidgetDemo = false // if it is demo widget in settings

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'widget',
      properties: ['apiKey', 'parentURL', 'key', 'name', 'pathname', 'theme'],
      storage: window.sessionStorage,
    })
  }

  // Widget settings

  setWidgetApiKey(key: any): void {
    if (key !== this.apiKey) {
      this.reset()
    }

    this.apiKey = String(key)
    this.isWidget = key !== undefined && this.apiKey?.length > 0
  }

  setParentURL(urlString: string): void {
    this.parentURL = urlString

    const url = new URL(urlString)

    this.key = url.host + url.pathname
    this.name = url.host

    if (url?.search) {
      const id = url?.searchParams?.get('openedAuthencityPublicationID') || ''

      if (+id > 0) {
        this.openedPostID = id
      }
    }
  }

  setPublicationsCount(count: any) {
    if (+count >= 0) {
      this.publicationsCount = +count
    } else {
      this.publicationsCount = 5
    }
  }

  getWidgetAuthHeaders() {
    return {
      'API-KEY': this.apiKey,
    }
  }

  getWidgetParams() {
    return {
      key: this.key,
      name: this.name,
    }
  }

  onCreatePost(): void {
    this.totalCommentsCount++
  }

  setWidgetPathname(pathname: string): void {
    this.pathname = pathname
  }

  setWidgetTheme(theme: IWidgetTheme): void {
    this.theme = {
      ...theme,
      isCustom: ['customLight', 'customDark'].includes(theme?.theme!),
    }
  }

  setWidgetIsDemo(): void {
    this.isWidgetDemo = true
  }

  setOpenedPostID(id: string): void {
    this.openedPostID = id
  }

  getWidgetBackground(adjust = 15) {
    return this?.theme?.isCustom
      ? {
          background: adjustHexColor(
            this?.theme?.themeBG,
            this?.theme?.theme === 'customLight' ? adjust : -adjust
          ),
          color: this?.theme?.themeColor,
        }
      : {}
  }

  reset(): void {
    this.apiKey = ''
    this.pathname = ''
    this.publicationsCount = 0
    this.theme = { isCustom: false }

    this.openedPostID = ''

    this.publications = []

    this.scrollPositionPublications = 0
    this.scrollPositionPublicationsWindow = 0

    this.pageSettingsPublications = defaultLoadPage
    this.startTotalItemsPublications = 0
    this.totalCommentsCount = 0

    this.isWidget = false
    this.isWidgetDemo = false

    if (sessionStorage.getItem('widget')) {
      sessionStorage.removeItem('widget')
    }
  }

  // Start Settings

  async getApiKey(name: string, website: string): Promise<string> {
    const response = await api.get(`/api/Widget/getApiKey`, {
      params: {
        name,
        website,
      },
      headers: this.getWidgetAuthHeaders(),
    })

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  // End Settings

  // Start Publications

  setPublications(value: IPublication[]) {
    this.publications = value
  }

  setScrollPositionPublications(value: number) {
    this.scrollPositionPublications = value
  }

  setScrollPositionPublicationsWindow(value: number) {
    this.scrollPositionPublicationsWindow = value
  }

  setPageSettingsPublications(value: ILoadPage) {
    this.pageSettingsPublications = value
    this.totalCommentsCount = value.TotalItems
  }

  setStartTotalItemsPublications(value: number) {
    this.startTotalItemsPublications = value
  }

  async getPublications(page: ILoadPage) {
    const response = await api.get(`/api/Widget/getAllPublicationsWithFilter`, {
      params: {
        pageNumber: page.PageNumber,
        pageSize: page.PageSize,
        ...this.getWidgetParams(),
      },
      headers: this.getWidgetAuthHeaders(),
    })

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async deletePublicationFromStore(id: number) {
    this.publications = this.publications?.filter(e => e?.Id !== id)
    this.publicationsCount--
  }

  // for Widget component

  async getTopPublication(
    onStartLoading: () => void,
    onEndLoading: () => void,
    openPostID: string
  ) {
    try {
      if (this.publicationsCount === 0 && !openPostID) {
        return
      }

      onStartLoading()

      if (openPostID) {
        const response = await PublicationStore.getThreadPublications(
          +openPostID
        )

        this.setPublications(response)
        this.setStartTotalItemsPublications(1)
        this.setPageSettingsPublications({
          PageNumber: 1,
          PageSize: 1,
          TotalItems: 1,
          TotalPages: 1,
        })
      } else {
        const response = await this.getPublications({
          ...this.pageSettingsPublications,
          PageSize: this.publicationsCount,
        })
        const { ListItems, TotalItems, PageNumber, PageSize, TotalPages } =
          response
        this.setPublications(ListItems)
        this.setStartTotalItemsPublications(TotalItems)
        this.setPageSettingsPublications({
          PageNumber,
          PageSize,
          TotalItems,
          TotalPages,
        })
      }

      onEndLoading()
    } catch (e: any) {
      console.log(e.message)
      onEndLoading()
      toast({
        type: 'error',
        message: 'Error when retrieving the information. Please try again.',
      })
    }
  }

  setDefaultPageSettingsPublications() {
    this.setStartTotalItemsPublications(0)
    this.setPageSettingsPublications(defaultLoadPage)
    this.setPublications([])
  }

  async getPublication(
    isLoading: boolean,
    onStartLoading: () => void,
    onEndLoading: () => void,
    isNewProfile: boolean,
    isRefresh: boolean,
    paramPage: ILoadPage,
    total?: number
  ) {
    if (isLoading) return

    try {
      const newParamPage = paramPage ? paramPage : this.pageSettingsPublications

      onStartLoading()
      let newList =
        isNewProfile || isRefresh ? [] : cloneDeep(this.publications)
      const response = await this.getPublications(newParamPage)

      if (response?.ListItems) {
        const { ListItems, TotalItems, PageNumber, PageSize, TotalPages } =
          response
        newList = newList.concat(ListItems)
        this.setPageSettingsPublications({
          PageNumber,
          PageSize,
          TotalItems,
          TotalPages,
        })
        this.setPublications(newList)
        this.startTotalItemsPublications === 0 &&
          this.setStartTotalItemsPublications(TotalItems)
      }

      onEndLoading()
    } catch (e: any) {
      console.log(e.message)
      onEndLoading()
      toast({
        type: 'error',
        message: 'Error when retrieving the information. Please try again.',
      })
    }
  }

  showMockedPublications(): void {
    this.publications = WIDGET_DEMO_PUBLICATIONS as any
    this.totalCommentsCount = WIDGET_DEMO_PUBLICATIONS.length
  }

  // End Publications
}

export default new Widget()
