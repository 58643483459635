import React, { CSSProperties, FC } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'

import { CustomTooltip } from 'components/CustomTooltip'

import { useStore } from 'store'

import ActivatedUserIcon from 'sources/images/activationIcon.svg'

import { DeactivatedUserMode } from './DeactivatedUserMode'

import styles from './styles.module.scss'

interface IProps {
  activated?: boolean
  style?: CSSProperties
  disablePortal?: boolean
  deactivatedMode?: boolean
  currentUser?: boolean
}

//Resize
export const ActivatedUser: FC<IProps> = observer(
  ({ style, activated, disablePortal, deactivatedMode, currentUser }) => {
    const { profile } = useStore()

    if (!activated && !deactivatedMode) return null

    const titleText = currentUser
      ? 'Your profile is Activated:'
      : 'This user is Activated:'

    return (
      <CustomTooltip
        placement={'top'}
        disablePortal={disablePortal}
        stopPropagation
        title={
          deactivatedMode && !activated ? (
            <DeactivatedUserMode />
          ) : (
            <div className={styles.tooltip}>
              <div className={styles.tooltipTitle}>{titleText}</div>
              <div>● Made 1 post onto ethereum/polygon</div>
              <div>● At least 2000 AUTH of profile backing</div>
              <div>● Has 7+ activated backers</div>
              <div>● Twitter connected</div>
            </div>
          )
        }
      >
        <img
          src={ActivatedUserIcon}
          className={cn({
            [styles.activatedUser]: true,
            [styles.deactivatedUser]: !activated,
          })}
          alt=""
          style={style}
        />
      </CustomTooltip>
    )
  }
)

ActivatedUser.displayName = 'ActivatedUser'
