export const WIDGET_DEMO_PUBLICATIONS = [
  {
    Id: 0,
    VideoId: 0,
    Text: '{"blocks":[{"key":"8s7lg","text":"This is Demo Message","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    BlockchainId: 0,
    DateCreation: '2025-01-01T00:00:00.000000',
    PublicationKey: null,
    LikeCount: 0,
    CommentCount: 0,
    BackedCount: 0,
    ViewsCount: 0,
    NetworkType: 2,
    Bid: null,
    SoldPrice: null,
    IsThread: false,
    ThreadMainMessage: null,
    ThreadNumber: 0,
    IsAdultContent: false,
    IpfsFiles: [],
    TagDTOs: [],
    Pinned: false,
    UserId: '',
    OwnerNickName: 'widget',
    OwnerFirstName: 'Demo',
    OwnerLastName: 'Widget',
    OwnerImageLink: null,
    OwnerOpenSeaLink: null,
    OwnerWallet: '',
    OwnerActivated: true,
    OwnerPrice: 0,
    IsStreaming: false,
    CreatorId: '',
    NickName: 'widget',
    FirstName: 'Demo',
    LastName: 'Widget',
    ImageLink: '',
    OpenSeaLink: null,
    CreatorWallet: '',
    Activated: false,
    Follow: false,
    Favorite: false,
    Liked: false,
    Retweet: false,
    RetweetCount: 0,
    IsEncrypted: false,
    CanDecrypt: '',
    SoldCount: 0,
    IsSuperpost: false,
    SuperpostInfo: {
      IsOpened: false,
      PublicText: null,
      PriceToOpenPost: 0,
      OpenedBy: [],
      HasImages: 0,
      Has3DFile: 0,
      HasFiles: 0,
      Has360Image: 0,
      HasGif: 0,
      HasVideos: 0,
      HasAudio: 0,
    },
    IsBlogpost: false,
    BlogpostInfo: {
      BlogpostPreviewLink: null,
      BlogpostTitle: null,
      BlogpostDescription: null,
    },
    IsWidgetPost: true,
    WidgetPostInfo: {
      WidgetSite: '',
      WidgetUrl: '',
    },
    IsStreampost: false,
    RetweetData: null,
    Comments: [],
  },
]
