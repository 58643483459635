import { createContext, useContext } from 'react'
import { configure, makeAutoObservable } from 'mobx'
import remotedev from 'mobx-remotedev'

import main from './main'
import profile from './profile'
import loading from './loading'
import donate from './donate'
import publication from './publication'
import video from './video'
import theme from './theme'
import following from './following'
import favorite from './favorite'
import like from './like'
import tweets from './tweets'
import paymentCurrency from './paymentCurrency'
import authToken from './authToken'
import pageActivities from './pageActivities'
import comments from './comments'
import login from './login'
import createPost from './createPost'
import invite from './invite'
import signalR from './signalR'
import transaction from './transaction'
import statuses from './statuses'
import walletConnect from './walletConnect'
import nft from './nft'
import notification from './notification'
import bid from './bid'
import videoChat from './videoChat'
import marketplace from './marketplace'
import systemStatistic from './systemStatistic'
import admin from './admin'
import top from './top'
import liveFeed from './liveFeed'
import pendingVideos from './pandingVideos'
import mediaQuery from './mediaQuery'
import search from './search'
import twitter from './twitter'
import videoStream from './videoStream'
import twoFactor from './twoFactor'
import faq from './faq'
import widget from './widget'
import coins from './coins'
import userOnline from './userOnline'

configure({ enforceActions: 'observed' })

@remotedev({ global: true })
class RootStore {
  // constructor() {
  //   makeAutoObservable(this)
  // }

  main = main
  profile = profile
  loading = loading
  donate = donate
  publication = publication
  video = video
  theme = theme
  following = following
  favorite = favorite
  like = like
  tweets = tweets
  paymentCurrency = paymentCurrency
  authToken = authToken
  pageActivities = pageActivities
  comments = comments
  login = login
  createPost = createPost
  invite = invite
  signalR = signalR
  transaction = transaction
  statuses = statuses
  walletConnect = walletConnect
  nft = nft
  notification = notification
  bid = bid
  videoChat = videoChat
  marketplace = marketplace
  systemStatistic = systemStatistic
  admin = admin
  top = top
  liveFeed = liveFeed
  pendingVideos = pendingVideos
  mediaQuery = mediaQuery
  search = search
  twitter = twitter
  videoStream = videoStream
  twoFactor = twoFactor
  faq = faq
  widget = widget
  coins = coins
  userOnline = userOnline
}

const store = new RootStore()

export const StoreContext = createContext<RootStore>(store)

export const useStore = (): RootStore => {
  const store = useContext(StoreContext)
  if (!store) {
    throw new Error('You have forgot to use StoreProvider, shame on you.')
  }
  return store
}

export default store
