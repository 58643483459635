import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
// @ts-ignore
import { appleAuthHelpers, useScript } from 'react-apple-signin-auth'
import CacheBuster from 'react-cache-buster'
import { Route, Routes } from 'react-router-dom'

import { useAccount } from 'wagmi'

import { iframely } from '@iframely/embed.js'

import packageInfo from '../package.json'

import { useMediaQuery, useOnlineUsers, useSignalR } from './utils/hooks'
import {
  auth_token_link,
  customHistory,
  dashboard,
  dashboard_active,
  FAQ_link,
  hashtags_posts,
  invitation_about_authencity,
  invitation_change_nickname,
  invitation_create_first_post,
  isMobile,
  live_feed_link,
  login_link,
  LoginProvider,
  main_link,
  marketplace_link,
  notifications_link,
  privacy_policy_link,
  registration_link,
  restore_account,
  shorts_video_link,
  single_post,
  single_video,
  terms_of_service_link,
  testSocketIO,
  top_link,
  twitter_auth,
  twitter_callback,
  twitter_sign_up_link,
  twitterinvite,
  user_info_link,
  user_link,
  user_videos_link,
  versions_link,
  video_chat_link,
  video_feed_link,
  video_post_link,
  video_stream_link,
  widget,
  widget_page,
} from 'utils'
import { useSignalRMethodAuth } from 'utils/hooks/useSignalR'

import { BottomBanner } from './components/BottomBanner'
import { BrowserRouter } from './components/BrowserRouter'
import { TestIOSAppBanner } from './components/TestIOSAppBanner'
// import { default as BottomAppBarPWA } from './componentsPWA/BottomAppBarPWA'
import { DrawerMenuPWA } from './componentsPWA/DrawerMenuPWA'
import { Cookie } from 'components/Cookie'
import Loading from 'components/Loading'
import ModalInstallMetaMask from 'components/ModalInstallMask'
import { NFTInfoModal } from 'components/NFTInfoModal'
import PrivateRoute from 'components/PrivateRoute'
import Toast, { TToastOpen } from 'components/Toast'

import { useStore } from './store'
import AuthTokenStore from 'store/authToken'
import CoinsStore from 'store/coins'
import MediaQueryStore from 'store/mediaQuery'
import ProfileStore from 'store/profile'
import ThemeStore from 'store/theme'

import { SocketIOTest } from './containers/SocketIOTest'

const NotFound = lazy(() => import('containers/NotFound'))
const FAQ = lazy(() => import('containers/FAQ'))
const HashTagsPostsPage = lazy(() => import('containers/HashTagsPostsPage'))
const MainPage = lazy(() => import('containers/MainPage'))
const Profile = lazy(() => import('containers/Profile'))
const RegistrationPage = lazy(() => import('containers/RegistrationPage'))
const AuthenticationPage = lazy(() => import('containers/AuthenticationPage'))
const SinglePostPage = lazy(() => import('containers/SinglePostPage'))
const TwitterCallback = lazy(() => import('containers/TwitterCallback'))
const TwitterAuth = lazy(() => import('containers/TwitterAuth'))
const UserInfoPage = lazy(() => import('containers/UserInfoPage'))
const AUTHToken = lazy(() => import('containers/AUTHToken'))
const Notifications = lazy(() => import('containers/Notifications'))
const MarketplacePage = lazy(() => import('containers/MarketplacePage'))
const VideoLibrarySingle = lazy(() => import('containers/VideoLibrarySingle'))
const VideoLibraryFeed = lazy(() => import('containers/VideoLibraryFeed'))
const UserVideos = lazy(() => import('containers/UserVideos'))
const VideoChatPage = lazy(() => import('containers/VideoChatPage'))
const VideoPostPage = lazy(() => import('containers/VideoPostPage'))
const Versions = lazy(() => import('containers/Versions'))
const LiveFeed = lazy(() => import('containers/LiveFeed'))
const TopPage = lazy(() => import('containers/TopPage'))
const TwitterSignUp = lazy(() => import('containers/TwitterSignUp'))
const TwitterInviteRedirect = lazy(
  () => import('containers/TwitterInviteRedirect')
)
const VideoShortsPage = lazy(() => import('containers/VideoShortsPage'))
const TermsOfService = lazy(() => import('containers/TermsOfService'))
const PrivacyPolicy = lazy(() => import('containers/PrivacyPolicy'))
const VideoStreamPage = lazy(() => import('containers/VideoStreamPage'))
const InvitationNewAccount = lazy(
  () => import('containers/InvitationNewAccount')
)
const RestoreAccount = lazy(() => import('containers/RestoreAccount'))
const Dashboard = lazy(() => import('containers/Dashboard'))
const Widget = lazy(() => import('containers/Widgets/Widget'))
const AuthencityWidget = lazy(
  () => import('containers/Widgets/AuthencityWidget')
)
const WidgetSettings = lazy(() => import('containers/Widgets/WidgetSettings'))

const BottomAppBarPWA = lazy(() => import('./componentsPWA/BottomAppBarPWA'))

const { ethereum } = window

export const toastRef = React.createRef() as any
const MyComponentToast = React.forwardRef(Toast)
export const toast = (props: TToastOpen) => {
  toastRef?.current?.open(props)
}

const App: React.FC = observer(() => {
  const { theme, profile, signalR, notification, main } = useStore()
  const isProduction = process.env.NODE_ENV === 'production'

  const [isShowingLoader, setIsShowingLoader] = useState(true)

  const getReceiveNotificationsCount = (value: number) => {
    console.log('ReceiveNotificationsCount..............on', value)
    notification.setNotificationsCountUnread(value)
  }

  useAppTheme()
  useLoginProvider(profile.getLoginProvider())
  // useWalletConnect()
  useSignalR(
    profile.selectedUser.Id ||
      profile.selectedProfileId ||
      profile.selectedProfileIdSession
  )
  useSignalRMethodAuth(
    'ReceiveNotificationsCount',
    signalR.connection,
    signalR.status,
    getReceiveNotificationsCount,
    profile.selectedUser.Id
  )
  useAuthPrice()
  useIframely(theme.theme)
  useMediaQueryStore()
  useUserInfoByTag()
  useLoadingScreen(setIsShowingLoader)
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC)
  use2FA()
  useOnlineUsers(true)

  // make custom hook usePWA
  useEffect(() => {
    function getPWADisplayMode() {
      const isStandalone = window.matchMedia(
        '(display-mode: standalone)'
      ).matches
      if (document.referrer.startsWith('android-app://')) {
        return 'twa'
      } else if (
        ('standalone' in window.navigator && window.navigator.standalone) ||
        isStandalone
      ) {
        return 'standalone'
      }
      return 'browser'
    }

    const mode = getPWADisplayMode()

    if (mode === 'standalone') {
      console.log('PWA Installed')

      if (window.innerWidth <= 600 && isMobile()) {
        main.setIsPWA(true)
      }
    }

    window
      .matchMedia('(display-mode: standalone)')
      .addEventListener('change', evt => {
        let displayMode = 'browser'
        if (evt.matches) {
          displayMode = 'standalone'
        }
        // Log display mode change to analytics
        console.log('DISPLAY_MODE_CHANGED', displayMode)

        const mode = getPWADisplayMode()

        console.log('DISPLAY_MODE', mode)
        if (mode === 'standalone') {
          console.log('PWA Installed')

          if (window.innerWidth <= 600 && isMobile()) {
            main.setIsPWA(true)
          }
        }
      })
  }, [])

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading active />} //If not pass, nothing appears at the time of new version check.
    >
      <>
        {/*<ResizeInput />*/}
        <Loading />
        <Cookie />
        <TestIOSAppBanner showBanner={!isShowingLoader} />
        <NFTInfoModal />
        <MyComponentToast ref={toastRef} />
        <ModalInstallMetaMask />
        <BrowserRouter>
          {main.isPWA ? (
            <>
              <BottomAppBarPWA />
              <DrawerMenuPWA />
            </>
          ) : null}
          <Suspense fallback={<Loading active />}>
            <Routes>
              <Route path={login_link()} element={<AuthenticationPage />} />
              <Route path={FAQ_link()} element={<FAQ />} />
              <Route path={hashtags_posts()} element={<HashTagsPostsPage />} />
              <Route path={live_feed_link()} element={<LiveFeed />} />
              <Route path={user_link()} element={<Profile />} />
              <Route
                path={registration_link()}
                element={<RegistrationPage />}
              />
              <Route path={single_post()} element={<SinglePostPage />} />
              <Route path={top_link()} element={<TopPage />} />
              <Route path={twitter_callback()} element={<TwitterCallback />} />
              <Route path={twitter_auth()} element={<TwitterAuth />} />
              <Route
                path={twitter_sign_up_link()}
                element={<TwitterSignUp />}
              />
              <Route path={user_info_link()} element={<UserInfoPage />} />
              <Route path={versions_link()} element={<Versions />} />
              <Route path={video_feed_link()} element={<VideoLibraryFeed />} />
              <Route path={user_videos_link()} element={<UserVideos />} />
              <Route path={single_video()} element={<VideoLibrarySingle />} />
              <Route path={shorts_video_link()} element={<VideoShortsPage />} />
              <Route
                path={terms_of_service_link()}
                element={<TermsOfService />}
              />
              <Route path={privacy_policy_link()} element={<PrivacyPolicy />} />
              <Route path={video_stream_link()} element={<VideoStreamPage />} />
              <Route
                path={auth_token_link()}
                element={
                  <PrivateRoute>
                    <AUTHToken />
                  </PrivateRoute>
                }
              />
              <Route
                path={marketplace_link()}
                element={
                  <PrivateRoute>
                    <MarketplacePage />
                  </PrivateRoute>
                }
              />
              <Route
                path={notifications_link()}
                element={
                  <PrivateRoute>
                    <Notifications />
                  </PrivateRoute>
                }
              />
              <Route
                path={video_chat_link()}
                element={
                  <PrivateRoute>
                    <VideoChatPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={video_post_link()}
                element={
                  <PrivateRoute>
                    <VideoPostPage />
                  </PrivateRoute>
                }
              />
              <Route path={main_link()} element={<MainPage />} />
              <Route
                path={twitterinvite()}
                element={<TwitterInviteRedirect />}
              />
              <Route
                path={invitation_change_nickname()}
                element={<InvitationNewAccount page={'nickname'} />}
              />
              <Route
                path={invitation_create_first_post()}
                element={<InvitationNewAccount page={'publication'} />}
              />
              <Route
                path={invitation_about_authencity()}
                element={<InvitationNewAccount page={'aboutAuthencity'} />}
              />
              <Route path={restore_account()} element={<RestoreAccount />} />
              <Route path={dashboard()} element={<Dashboard />} />
              <Route path={dashboard_active()} element={<Dashboard />} />
              <Route path={widget()} element={<Widget />} />
              <Route path={widget_page()} element={<AuthencityWidget />} />
              <Route path={testSocketIO()} element={<SocketIOTest />} />
              {/*<Route path={'/not-found'} element={<NotFound/>}/>*/}
              {/*<Route path={'*'} element={<Navigate to="/not-found" replace />}/>*/}
              <Route path={'*'} element={<NotFound />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </>
    </CacheBuster>
  )
})

App.displayName = 'App'

export default App

const useLoginProvider = (loginProvider: LoginProvider | string) => {
  const loginProviderRef = useRef<LoginProvider | string>(loginProvider)
  loginProviderRef.current = loginProvider
  console.log('loginProviderRef.current', loginProviderRef.current)

  const firstRenderRef = useRef<boolean>(true)
  const metamaskAnswered = useRef<boolean>(false)

  const clearUserSessionData = () => {
    if (!ProfileStore?.selectedUser?.DeletionMark) {
      console.log('clearUserSessionData')
      const verify_twitter_nickname = sessionStorage.getItem(
        'verify_twitter_nickname'
      )
      const verify_twitter_subscription = sessionStorage.getItem(
        'verify_twitter_subscription'
      )
      ProfileStore.clearSessionProfileData()
      ProfileStore.clearSessionTokenData()
      !verify_twitter_nickname &&
        !verify_twitter_subscription &&
        customHistory.push(main_link())
    }
  }

  // WalletConnect ----⬇︎⬇︎⬇︎
  const { isConnected, address } = useAccount()
  // const { disconnect: disconnectWalletConnect } = useDisconnect()

  // Handle Account Change
  useEffect(() => {
    if (ProfileStore.getLoginProvider() === LoginProvider.WalletConnect) {
      if (
        !(
          ProfileStore.getWallet() &&
          address &&
          address?.toLowerCase() === ProfileStore.getWallet()
        )
      ) {
        clearUserSessionData()
        console.log('195')
      }
    }
  }, [address])
  // WalletConnect ----⬆︎⬆︎⬆︎

  useEffect(() => {
    const loadUserInfo = async () => {
      try {
        const selectedUserToken = ProfileStore.getToken()
        if (selectedUserToken) {
          await ProfileStore.refreshSessionTokenData()

          const response = await ProfileStore.getProfileByToken()
          ProfileStore.setSessionProfileData(response, true)
        } else {
          clearUserSessionData()
        }
      } catch (e) {
        toast({
          type: 'error',
          message: `Error getting user information. Please refresh the page!`,
        })
        console.log('e', e)
      }
    }

    // METAMASK ------------------------------------------------ ⬇︎⬇︎⬇︎
    let timerCheckMetaMask: NodeJS.Timeout

    // const userIsLoggedToMetamask = async () => {
    //   if (loginProviderRef.current === LoginProvider.Metamask) {
    //     if (ethereum) {
    //       await ethereum.request({ method: 'eth_requestAccounts' })
    //       const userWallets = await ethereum.request({ method: 'eth_accounts' })
    //
    //       if (userWallets.length === 0) {
    //         clearUserSessionData()
    //         console.log('232')
    //       }
    //     } else {
    //       clearUserSessionData()
    //       console.log('236')
    //     }
    //   }
    // }

    // Handle Account Change
    const handleAccountChange = (accounts: string[]) => {
      if (loginProviderRef.current === LoginProvider.Metamask) {
        if (
          !(
            ProfileStore.getWallet() &&
            accounts.length > 0 &&
            accounts[0] === ProfileStore.getWallet()
          )
        ) {
          clearUserSessionData()
          console.log('246')
        }
      }
    }

    const checkMetamaskWalletAndGetUserInfo = async (
      isFirstRender: boolean
    ) => {
      try {
        // Metamask bug fix
        // This will log out user when Metamask is locked
        metamaskAnswered.current = false
        setTimeout(() => {
          if (!metamaskAnswered.current) {
            window.location.reload()
            // window.location.href=window.location.href
          }
        }, 500)

        // Check if user is logged in Metamask
        const userWallets = await ethereum.request({ method: 'eth_accounts' })
        const userWallet = userWallets[0]

        if (!userWallet) {
          throw new Error('User is not logged in Metamask')
        }

        await ethereum.request({ method: 'eth_requestAccounts' })
        metamaskAnswered.current = true
        // const userWallets = await ethereum.request({ method: 'eth_accounts' })
        // const userWallet = userWallets[0]

        if (userWallet === ProfileStore.getWallet()) {
          isFirstRender && loadUserInfo()
          ethereum?.on('accountsChanged', handleAccountChange)
          // timerCheckMetaMask = setInterval(userIsLoggedToMetamask, 1000)
        } else {
          clearUserSessionData()
          console.log('263')
        }
      } catch (e) {
        clearUserSessionData()
        console.log('267')
      }
    }

    if (loginProviderRef.current === LoginProvider.Metamask) {
      if (ethereum) {
        checkMetamaskWalletAndGetUserInfo(firstRenderRef.current)
      } else {
        clearUserSessionData()
        console.log('276')
      }
    }
    // METAMASK ------------------------------------------------ ⬆︎⬆︎⬆︎

    // WALLETCONNECT ------------------------------------------------ ⬇︎⬇︎⬇︎
    if (loginProviderRef.current === LoginProvider.WalletConnect) {
      if (
        isConnected &&
        address &&
        address.toLowerCase() === ProfileStore.getWallet()
      ) {
        firstRenderRef.current && loadUserInfo()
      } else {
        clearUserSessionData()
        console.log('287')
      }
    }
    // WALLETCONNECT ------------------------------------------------ ⬆︎⬆︎⬆︎

    // GOOGLE | APPLE | TWITTER ------------------------------------------------ ⬇︎⬇︎⬇︎
    if (
      loginProviderRef.current === LoginProvider.Google ||
      loginProviderRef.current === LoginProvider.Apple ||
      loginProviderRef.current === LoginProvider.Twitter
    ) {
      firstRenderRef.current && loadUserInfo()
    }
    // GOOGLE | APPLE | TWITTER ------------------------------------------------ ⬆︎⬆︎⬆︎

    return () => {
      ethereum?.removeListener('accountsChanged', handleAccountChange)
      clearInterval(timerCheckMetaMask)
    }
  }, [loginProvider])
}

const useAuthPrice = () => {
  useEffect(() => {
    const getAUTHPrice = async () => {
      const response = await AuthTokenStore.getAUTHPrice()
      if (response) {
        AuthTokenStore.setAUTHPrice({
          ETHPriceForAUTH: response.authencity.eth ?? 0,
          USDPriceForAUTH: response.authencity.usd ?? 0,
        })
      }
    }

    const getCoinsPrice = async () => {
      const response = await CoinsStore.getCoinsExchangeRate()
      // const response = CoinsStore.mockedData

      if (response) {
        CoinsStore.setCoinsExchangeRate(response)
      }
    }

    getAUTHPrice()
    let timer = setInterval(() => {
      getAUTHPrice()
    }, 300000)

    getCoinsPrice()
    let timerMarket = setInterval(() => {
      getCoinsPrice()
    }, 60000)

    return () => {
      clearInterval(timer)
      clearInterval(timerMarket)
    }
  }, [])
}

const useIframely = (theme: string) => {
  useEffect(() => {
    if (iframely) {
      iframely.load()
      iframely.extendOptions({
        api_key: process.env.REACT_APP_IFRAMELY_API_KEY,
        iframe: 'card',
      })
      iframely.setTheme(theme)
    }
  }, [iframely, theme])
}

const useMediaQueryStore = () => {
  const minWidth_2200 = useMediaQuery('(min-width: 2201px)')

  useEffect(() => {
    MediaQueryStore.setMinWidth_2200(minWidth_2200)
  }, [minWidth_2200])
}

const useAppTheme = () => {
  useEffect(() => {
    const setThemeFromLocalStorage = (htmlEl: any) => {
      const themeLocalStorage = localStorage.getItem('theme')
      if (themeLocalStorage) {
        htmlEl.dataset.theme = themeLocalStorage
        ThemeStore.setTheme(themeLocalStorage)
      } else {
        htmlEl.dataset.theme = 'light'
        // theme.setTheme('light');
      }
    }

    const setWidgetTheme = (htmlEl: any, theme: string) => {
      htmlEl.dataset.theme = theme
      ThemeStore.setTheme(theme)
    }

    const setTheme = () => {
      const htmlEl = document.getElementsByTagName('html')[0]

      const hasCustomTheme = window.location.pathname.includes('widget/')
      if (hasCustomTheme) {
        const params: any = new URLSearchParams(window.location.search)
        const themeTypeWidget = params?.get?.('theme')
        const themeBGWidget = params?.get?.('themeBG')
        const themeColorWidget = params?.get?.('themeColor')

        if (['light', 'dark'].includes(themeTypeWidget)) {
          setWidgetTheme(htmlEl, themeTypeWidget)
        } else if (['customLight', 'customDark'].includes(themeTypeWidget)) {
          const _themeTypeWidget =
            themeTypeWidget === 'customLight' ? 'light' : 'dark'

          setWidgetTheme(htmlEl, _themeTypeWidget)

          const root = document.documentElement
          root?.style.setProperty('--backgroundColor', themeBGWidget)
          root?.style.setProperty('--mainColor', themeColorWidget)
        } else {
          setThemeFromLocalStorage(htmlEl)
        }
      } else {
        setThemeFromLocalStorage(htmlEl)
      }
    }

    setTheme()
  }, [])
}

const useLoadingScreen = (setIsShowingLoader: (toShow: boolean) => void) => {
  useEffect(() => {
    const hideLoadingScreen = () => {
      const loadingScreenEl = document.getElementById('loadingScreen')
      if (loadingScreenEl) {
        loadingScreenEl.style.opacity = '0'
        loadingScreenEl.style.zIndex = '-999999'
        setIsShowingLoader(false)

        setTimeout(() => {
          loadingScreenEl.remove()
        }, 1000)
      }
    }

    const timeout = setTimeout(hideLoadingScreen, 5000)

    const onAppLoaded = () => {
      setTimeout(hideLoadingScreen, 500)
      clearTimeout(timeout)
    }

    window.addEventListener('load', onAppLoaded)

    return () => {
      window.removeEventListener('load', onAppLoaded)
    }
  }, [])
}

const useUserInfoByTag = () => {
  const clearUsersInfoByTag = () => {
    ProfileStore.usersInfoByTag.length > 0 && ProfileStore.setUsersInfoByTag([])
  }

  useEffect(() => {
    //Clear info every minute
    const intervalUsersInfoByTag = setInterval(clearUsersInfoByTag, 60000)

    return () => {
      clearInterval(intervalUsersInfoByTag)
    }
  }, [])
}

const use2FA = () => {
  const checkTrust2FA = () => {
    const userID = ProfileStore?.selectedProfileId
    const trustDate = ProfileStore.trust?.[userID]
    if (typeof trustDate === 'number') {
      const twoFactorEnabled =
        ProfileStore.selectedUser.IsSMSEnabled ||
        ProfileStore.selectedUser.IsAuthenticatorConnected

      if (twoFactorEnabled) {
        const dateNow = new Date()
        if (+dateNow > Number(trustDate)) {
          ProfileStore.clearSessionTokenData()
          ProfileStore.clearSessionProfileData()
          ProfileStore.removeTrust(userID)
          customHistory.push('/')
        }
      }
    }
  }

  useEffect(() => {
    if (ProfileStore?.selectedProfileId) {
      const timeout = setTimeout(checkTrust2FA, 3000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [ProfileStore?.selectedProfileId, ProfileStore?.trust])
}

//Turn off(don't delete)
// const addBeforeunloadListener = () => {
//   window.addEventListener('beforeunload', function(e) {
//     if (transaction.isActive) {
//       const confirmationMessage = 'Transaction data may be lost if you close your browser!!!';
//
//       (e || window.event).returnValue = confirmationMessage //Gecko + IE
//       return confirmationMessage                            //Webkit, Safari, Chrome
//     }
//   })
// }
