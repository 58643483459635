import { useEffect } from 'react'

import { connect } from 'socket.io-client'

import { useStore } from 'store'

const ACTIONS = require('utils/socket/actions')

const socket = connect(process.env.REACT_APP_BASE_URL, {
  path: '/usersOnline_socket.io',
})

export const useOnlineUsers = (init: boolean) => {
  const { userOnline } = useStore()

  const { profile } = useStore()

  useEffect(() => {
    if (init) {
      const interval = setInterval(() => {
        if (socket.id && profile.selectedUser.Id) {
          socket.emit(ACTIONS.NEW_USER, {
            nickname: profile.selectedUser.NickName,
            userID: profile.selectedUser.Id,
            socketID: socket.id,
          })

          clearInterval(interval)
        }
      }, 500)

      return () => {
        clearInterval(interval)
      }
    }
  }, [socket.id, profile.selectedUser, init])

  useEffect(() => {
    if (init) {
      socket.emit(ACTIONS.GET_USERS)
    }
  }, [socket, init])

  // useEffect(() => {
  //   let ignore = false
  //
  //   socket.on('messageResponse', (data: any) => {
  //     if (!ignore) {
  //       setMessages(m => [...m, data])
  //     }
  //   })
  //
  //   return () => {
  //     ignore = true
  //   }
  // }, [socket])

  useEffect(() => {
    if (init) {
      socket.on(ACTIONS.ON_CONNECT, (data: any) =>
        userOnline.setUsersOnline(data)
      )

      socket.on(ACTIONS.NEW_USER_RESPONSE, (data: any) =>
        userOnline.addNewUser(data)
      )

      socket.on(ACTIONS.DISCONNECT_USER_RESPONSE, (data: any) =>
        userOnline.disconnectUser(data)
      )
    }
  }, [socket, init])

  const disconnectSocketIO = () => {
    socket.emit(ACTIONS.DISCONNECT_USER, {
      socketID: socket.id,
    })
  }

  return { users: userOnline.users, socket, disconnectSocketIO }
}
