import { useEffect, useState } from 'react'

import cloneDeep from 'lodash/cloneDeep'

import { toast } from '../../App'

import { IDraft, ILiveFeed, ILiveStream, ILoadPage } from '../../models'

import { useStore } from '../../store'
import videoStreamStore from '../../store/videoStream'

import { activeTabType, defaultLoadPage } from '../const'
import { useSignalRMethod } from './useSignalR'

interface IProps {
  setIsOpenCreatePostModal?: (isOpen: boolean) => void
}

export const useCreatePost = ({ setIsOpenCreatePostModal }: IProps) => {
  const { profile, loading, publication, widget, pageActivities, signalR } =
    useStore()
  const [tweetsPaginationNextToken, setTweetsPaginationNextToken] =
    useState<string>()
  const [isPublicationLoading, setPublicationLoading] = useState(false)

  const [drafts, setDrafts] = useState<IDraft[]>([])
  const [draftToEdit, setDraftToEdit] = useState<IDraft | null>(null)

  const onNewPublicationSignalR = (post: ILiveFeed) => {
    if (
      pageActivities.tab === activeTabType.feed &&
      post &&
      publication.lastCreatedPublicationId !== post.Id
    ) {
      pageActivities.setFeedPosts([post, ...pageActivities.feedPosts])
    }
  }

  useSignalRMethod(
    'ReceivePublication',
    signalR.connection,
    onNewPublicationSignalR
  )

  const setDefaultPostsSettings = () => {
    pageActivities.setStartTotalItems(0)
    pageActivities.setPageSettingsProfile(defaultLoadPage)
    publication.setPublication([])
    publication.setPinnedPublication(null)
    setTweetsPaginationNextToken(undefined)
  }

  const changeActiveTab = (value: activeTabType) => {
    if (value === pageActivities.tab) return
    setDefaultPostsSettings()
    pageActivities.setNumberOfNewPosts(0)
    pageActivities.setFeedPosts([])
    pageActivities.setTab(value)
    getPublication(false, true, defaultLoadPage, 0)
  }

  const getPublication = async (
    isNewProfile: boolean,
    isRefresh: boolean,
    paramPage: ILoadPage,
    total?: number
  ) => {
    if (isPublicationLoading) return
    const activeUserId = profile.getActiveProfileId()
    const selectedUserId = profile.getSelectedProfileId()

    try {
      const newPage = paramPage ? paramPage : pageActivities.pageSettingsProfile
      const startTotal =
        typeof total !== 'undefined' ? total : pageActivities.startTotalItems

      loading.setLoading(true)
      setPublicationLoading(true)
      let newList =
        isNewProfile || isRefresh ? [] : cloneDeep(publication.publicationList) //-◄-Create clone list publications
      const activeTabLocal = isNewProfile
        ? activeTabType.myPosts
        : pageActivities.tab
      let posts
      let drafts = []

      if (activeTabLocal === activeTabType.myPosts) {
        const response = await publication.getUserPublication(
          newPage,
          startTotal,
          activeUserId as string,
          publication.sortParameters
        ) //-◄-Get user publications
        const { Item1, Item2 } = response

        if (Item1) posts = Item1
        if (Item2 && Item2.Id) {
          publication.setPinnedPublication(Item2)
        }
      }
      if (activeTabLocal === activeTabType.feed) {
        posts = await publication.getAllPublication(
          newPage,
          startTotal,
          publication.sortParameters
        ) //-◄-Get all publications
      }
      if (activeTabLocal === activeTabType.following) {
        posts = await publication.getFollowingListPublication(
          newPage,
          startTotal,
          activeUserId as string,
          publication.sortParameters
        ) //-◄-Get all following
      }
      if (activeTabLocal === activeTabType.favorites) {
        if (profile.getSelectedProfileId() !== profile.getActiveProfileId()) {
          changeActiveTab(activeTabType.myPosts)
        } else {
          posts = await publication.getFavoriteListPublication(
            newPage,
            startTotal,
            publication.sortParameters
          ) //-◄-Get all favorite
        }
      }
      if (activeTabLocal === activeTabType.blogposts) {
        posts = await publication.getUserBlogposts(
          newPage,
          startTotal,
          activeUserId as string,
          publication.sortParameters
        ) //-◄-Get users blogpost publications
      }
      if (activeTabLocal === activeTabType.drafts) {
        drafts = await publication.getDrafts()
      }
      // if (activeTabLocal === 'Tweets') {
      //   if (profile.viewedUser.TwitterId) {
      //     const twitterTweets: ITweets = await tweets.getTweets(profile.viewedUser.TwitterId, tweetsPaginationNextToken)
      //     if (twitterTweets.data) {
      //       setTweetsPaginationNextToken(twitterTweets.meta.next_token)
      //       setProfileTwitterLink(`https://twitter.com/${twitterTweets.includes.users[0].username}`)
      //       const mappedPosts = twitterTweets.data.map(item => {
      //         let IpfsFile
      //         if (item.attachments) {
      //           const mediaInfo = twitterTweets.includes.media.filter(mediaItem => mediaItem.media_key === item.attachments?.media_keys[0])
      //           IpfsFile = {
      //             FileLink: mediaInfo[0].url,
      //             FileType: mediaInfo[0].type,
      //           }
      //         }
      //
      //         const tweetTextObject = tweets.getTweetText(item)
      //         let tweetText = ''
      //         if (tweetTextObject.text) {
      //           tweetText = tweetTextObject.text
      //         } else {
      //           if (!IpfsFile?.FileType) {
      //             tweetText = tweetTextObject.url
      //           }
      //         }
      //
      //         const IpfsFiles = IpfsFile ? [IpfsFile] : []
      //         return {
      //           Id: item.id,
      //           Text: tweetText,
      //           DateCreation: item.created_at,
      //           LikeCount: item.public_metrics.like_count,
      //           CommentCount: item.public_metrics.reply_count,
      //           RetweetCount: item.public_metrics.retweet_count,
      //           IpfsFiles: IpfsFiles,
      //           NickName: profile.viewedUser.NickName,
      //           FirstName: profile.viewedUser.FirstName,
      //           LastName: profile.viewedUser.LastName,
      //           ImageLink: twitterTweets.includes.users[0].profile_image_url,
      //           TweetLink: `https://twitter.com/${twitterTweets.includes.users[0].username}/status/${item.id}`,
      //         }
      //       })
      //       posts = { ListItems: mappedPosts }
      //     }
      //   } else {
      //     console.log('not connect')
      //   }
      // }

      if (activeTabLocal === activeTabType.widgetPosts) {
        if (widget.isWidget) {
          posts = await widget.getPublications(newPage) //-◄- Get widget publication after create new one
        } else {
          posts = await publication.getWidgetPublications(newPage, startTotal) //-◄- Get widget publications in Widget posts feed in profile page
        }
      }

      if (posts?.ListItems) {
        newList = newList.concat(posts.ListItems)

        if (activeTabLocal === activeTabType.widgetPosts) {
          if (widget.isWidget) {
            widget.setPublications(newList)
          } else {
            publication.setPublication(newList)
          }
        } else {
          publication.setPublication(newList)
        }

        if (activeTabLocal !== 'Tweets') {
          pageActivities.startTotalItems === 0 &&
            pageActivities.setStartTotalItems(posts.TotalItems)
          pageActivities.setPageSettingsProfile({
            PageNumber: posts.PageNumber,
            PageSize: posts.PageSize,
            TotalItems: posts.TotalItems,
            TotalPages: posts.TotalPages,
          })
        }
      }

      if (drafts.length === 0 || drafts.length > 0) {
        setDrafts(drafts)
      }

      loading.setLoading(false)
      setPublicationLoading(false)
    } catch (e: any) {
      console.log(e.message)
      loading.setLoading(false)
      setPublicationLoading(false)
      toast({
        type: 'error',
        message: 'Error when retrieving the information. Please try again.',
      })
    }
  }

  const getDrafts = async () => {
    try {
      if (pageActivities.tab === activeTabType.drafts) {
        await getPublication(false, true, defaultLoadPage, 0)
      }
    } catch (e) {
      console.log('getDrafts', e)
    }
  }

  const handleOpenCreatePostModal = (value: boolean) => {
    setDraftToEdit(null)
    setIsOpenCreatePostModal?.(value)
  }

  const onEditDraftPost = (draft: IDraft) => {
    setDraftToEdit(draft)
    setIsOpenCreatePostModal?.(true)
  }

  return {
    tweetsPaginationNextToken,
    drafts,
    draftToEdit,
    getPublication,
    getDrafts,
    handleOpenCreatePostModal,
    onEditDraftPost,
    setDefaultPostsSettings,
    changeActiveTab,
  }
}

export const useActiveStreams = (
  nickname: string | undefined,
  activeTab: activeTabType
): ILiveStream[] => {
  const [activeStreams, setActiveStreams] = useState<ILiveStream[]>([])
  useEffect(() => {
    getStreams()
  }, [nickname, activeTab])

  const getStreams = async () => {
    try {
      if (activeTab === activeTabType.myPosts && nickname) {
        const response = await videoStreamStore.getStreamInfoByNickname(
          nickname
        )
        if (response.MuxStreamId) {
          setActiveStreams([response])
        } else {
          setActiveStreams([])
        }
      } else if (activeTab === activeTabType.feed) {
        const response = await videoStreamStore.getAllStreams()
        setActiveStreams(response)
      } else {
        setActiveStreams([])
      }
    } catch (e) {
      console.log('getStreams', e)
    }
  }

  return activeStreams
}
