import { makeAutoObservable } from 'mobx'

import { IUserOnline } from 'models'

import ProfileStore from './profile'

class UserOnline {
  users: Array<IUserOnline> = []

  constructor() {
    makeAutoObservable(this)
  }

  checkUserIsOnline(nickName: string) {
    if (ProfileStore.selectedUser.NickName === nickName) {
      return false
    }

    return !!this.users?.find(e => e.nickname === nickName)?.isOnline
  }

  checkUserIsOnlineByID(id: string) {
    if (ProfileStore.selectedUser.NickName === id) {
      return false
    }

    return !!this.users?.find(e => e.userID === id)?.isOnline
  }

  setUsersOnline(users: IUserOnline[]) {
    this.users = users
  }

  addNewUser(userInfo: IUserOnline) {
    this.users = this.users.concat([userInfo])
  }

  disconnectUser(socketID: string) {
    this.users = this.users.filter(u => u?.socketID !== socketID)
  }
}

export default new UserOnline()
