import { action, makeAutoObservable } from 'mobx'

import { api } from '../utils'

class Search {
  constructor() {
    makeAutoObservable(this)
  }

  async searchUsersByTag(searchText: string) {
    const response = await api.get(
      `api/Search/getUsersToTag?searchText=${searchText}`
    )

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }
}

export default new Search()
