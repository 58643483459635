import { EditorState } from 'draft-js'
import { action, makeAutoObservable, observable } from 'mobx'

import {
  checkBadWords,
  checkNumberOfLineBreaks,
  copyToClipboardOnAndroid,
  getEditorStateAsString,
  getPostLengthFromEditorState,
  getPostTextFromEditorState,
  publicationCountType,
} from 'utils'
import { api } from 'utils/config'

import { toast } from '../App'

import type {
  IIpfsFiles,
  ILoadPage,
  IPublication,
  ISortParameters,
  ITopHashtag,
} from 'models'
import { IBlogpostPreviewData, ISendPublication } from 'models'

import CreatePostStore from 'store/createPost'

class PublicationStore {
  publicationList: IPublication[] = []
  pinnedPublication: IPublication | null = null
  hashtagList: ITopHashtag[] = []
  threadPublications: IPublication[] = []
  hashtagPublications: IPublication[] = []
  lastCreatedPublicationId: number | null = null
  sortParameters: ISortParameters = { sortBy: 0, orderByDescending: true }
  sortParametersByHashtag: ISortParameters = {
    sortBy: 0,
    orderByDescending: true,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setPinnedPublication = (value: IPublication | null) => {
    this.pinnedPublication = value
  }

  setHashtagPublications = (value: IPublication[]) => {
    this.hashtagPublications = value
  }

  setSortParametersByHashtag = (value: ISortParameters) => {
    this.sortParametersByHashtag = value
  }

  setSortParameters = (value: ISortParameters) => {
    this.sortParameters = value
  }

  setLastCreatedPublicationId = (value: number) => {
    this.lastCreatedPublicationId = value
  }

  setPublication = (value: IPublication[]) => {
    this.publicationList = value
  }

  setHashtagList = (value: ITopHashtag[]) => {
    this.hashtagList = value
  }

  updatePublication = (value: IPublication[]) => {
    this.publicationList = this.publicationList.map(item =>
      item.Id === value[0].Id ? value[0] : item
    )
  }

  setThreadPublications = (value: IPublication[]) => {
    this.threadPublications = value
  }

  async getUserPublication(
    page: ILoadPage,
    startTotalItems: number,
    userId: string,
    sortParameters: ISortParameters
  ) {
    const response = await api.get(`api/Publication/GetUserPublications`, {
      params: {
        startTotalItems,
        page: page.PageNumber,
        pageSize: page.PageSize,
        userId,
        sortBy: sortParameters.sortBy,
        orderByDescending: sortParameters.orderByDescending,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getUserBlogposts(
    page: ILoadPage,
    startTotalItems: number,
    userId: string,
    sortParameters: ISortParameters
  ) {
    const response = await api.get(`api/Publication/getAllBlogposts`, {
      params: {
        startTotalItems,
        page: page.PageNumber,
        pageSize: page.PageSize,
        userId,
        sortBy: sortParameters.sortBy,
        orderByDescending: sortParameters.orderByDescending,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getAllPublication(
    page: ILoadPage,
    startTotalItems: number,
    sortParameters: ISortParameters
  ) {
    const response = await api.get(`api/Publication/GetAllUserPublications`, {
      params: {
        startTotalItems,
        page: page.PageNumber,
        pageSize: page.PageSize,
        sortBy: sortParameters.sortBy,
        orderByDescending: sortParameters.orderByDescending,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getLiveFeedPublication() {
    const response = await api.get(`api/Publication/GetLiveFeed`)

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getFollowingListPublication(
    page: ILoadPage,
    startTotalItems: number,
    userId: string,
    sortParameters: ISortParameters
  ) {
    const response = await api.get(
      `/api/Publication/GetFollowingListPublication`,
      {
        params: {
          startTotalItems,
          page: page.PageNumber,
          pageSize: page.PageSize,
          userId,
          sortBy: sortParameters.sortBy,
          orderByDescending: sortParameters.orderByDescending,
        },
      }
    )

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getFavoriteListPublication(
    page: ILoadPage,
    startTotalItems: number,
    sortParameters: ISortParameters
  ) {
    const response = await api.get(
      `/api/Publication/GetFavoriteListPublication`,
      {
        params: {
          startTotalItems,
          page: page.PageNumber,
          pageSize: page.PageSize,
          sortBy: sortParameters.sortBy,
          orderByDescending: sortParameters.orderByDescending,
        },
      }
    )

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getWidgetPublications(page: ILoadPage, startTotalItems: number) {
    const response = await api.get(`api/Publication/getPublicationsForWidget`, {
      params: {
        startTotalItems,
        pageNumber: page.PageNumber,
        pageSize: page.PageSize,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getPublicationsByTag(
    page: ILoadPage,
    startTotalItems: number,
    tagId: string,
    sortParameters: ISortParameters
  ) {
    const response = await api.get(`api/Search/getPublicationsByTag`, {
      params: {
        tagId: Number(tagId),
        startTotalItems,
        pageNumber: page.PageNumber,
        pageSize: page.PageSize,
        sortBy: sortParameters.sortBy,
        orderByDescending: sortParameters.orderByDescending,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async addViewPublication(publicationId: number) {
    const response = await api.post(`api/AUTH/AddViews`, null, {
      params: {
        publicationId,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getThreadPublications(threadId: number) {
    const response = await api.get(`api/Publication/GetThreadPublications`, {
      params: {
        threadId,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getPublicationWithCommentForNotification(
    publicationId: number,
    commentId: number
  ) {
    const response = await api.get(
      `api/Publication/getPublicationWithCommentForNotification`,
      {
        params: {
          publicationId,
          commentId,
        },
      }
    )

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async deletePublication(publicationId: number) {
    const response = await api.delete(`api/Publication`, {
      params: {
        publicationId,
      },
    })

    if (response.status !== 204) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async createRePost(publicationId: number, retweetText: string) {
    const response = await api.post(`api/Publication/CreateRepost`, null, {
      params: {
        publicationId,
        retweetText,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async deleteRePost(publicationId: number) {
    const response = await api.delete(`api/Publication/DeleteRepost`, {
      params: {
        publicationId,
      },
    })

    if (response.status !== 204) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }
    return response.data
  }

  async getTopPublications(type: number, filter: number) {
    const response = await api.get(`api/Publication/getTopPublications`, {
      params: {
        sorting: type,
        filter: filter,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getPublicationOwners(publicationId: number) {
    const response = await api.get(`api/Publication/GetOwnersInfo`, {
      params: {
        publicationId,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getEncryptedText(
    publicationId: number,
    text: string,
    taggedNickname: string
  ) {
    // Need for correct work
    const replacedText = text.replaceAll("'", 'Ω')
    const response = await api.post(
      `api/Publication/getEncryptedText`,
      { text: replacedText },
      {
        params: {
          publicationId,
          taggedNickname,
        },
      }
    )

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }

    return response.data
  }

  async getDecryptedPost(id: number, hash: string) {
    const response = await api.get(`api/Publication/getDecryptedMessage`, {
      params: {
        publicationId: id,
        signatureHash: hash,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }

    return response.data
  }

  async getNonceForDecrypt(userWallet: string) {
    const response = await api.get(
      `api/Account/getNonce?walletAddress=${userWallet}`
    )

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }

    return response.data
  }

  async getEncryptedTextForSuperpost(
    publicationId: number,
    text: string,
    IpfsFiles: Array<{ CustomIpfsFileId: string; FileLink: string }>
  ) {
    // Need for correct work
    const replacedText = text.replaceAll("'", 'Ω')
    const response = await api.post(
      `api/Publication/getEncryptedTextForSuperpost`,
      { Text: replacedText, IpfsFiles },
      {
        params: { publicationId },
      }
    )

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }

    return response.data
  }

  async copyPublicationLink(publicationId: number) {
    try {
      await copyToClipboardOnAndroid(
        `${process.env.REACT_APP_BASE_URL}post/${publicationId}`
      )
      toast({
        type: 'success',
        message: 'The link to the post has been copied',
      })
    } catch (err) {
      toast({
        type: 'error',
        message: `Failed to copy`,
      })
    }
  }

  async getTopVideoPublications() {
    const response = await api.get(`api/Publication/getTopVideos`)
    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }

    return response.data
  }

  async getDrafts() {
    const response = await api.get(`api/Publication/getDrafts`)
    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }

    return response.data
  }

  async uploadImageForStreamPreview(formData: FormData) {
    const response = await api.post(
      `api/Publication/uploadImageForStreamPreview`,
      formData,
      {
        onUploadProgress: function (progressEvent) {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            console.log('percentCompleted:', percentCompleted)
          }
        },
      }
    )

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async uploadImagesForBlogpost(formData: FormData) {
    const response = await api.post(
      `api/Publication/uploadImagesForBlogpost`,
      formData,
      {
        onUploadProgress: function (progressEvent) {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            console.log('percentCompleted:', percentCompleted)
          }
        },
      }
    )

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async saveBlogpostAsDraft(
    text: EditorState,
    previewData: IBlogpostPreviewData | null
  ) {
    const response = await api.post(`api/Publication/saveAsDraft`, {
      Text: getEditorStateAsString(text),
      BlogpostTitle: previewData?.title
        ? getEditorStateAsString(previewData.title)
        : '',
      BlogpostDescription: previewData?.description
        ? getEditorStateAsString(previewData.description)
        : '',
      BlogpostPreviewLink: previewData?.preview ? previewData.preview : '',
      BlogpostPreviewLinkCdn: previewData?.previewCdn
        ? previewData.previewCdn
        : '',
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async updateDraftPost(
    id: string,
    text: EditorState,
    previewData: IBlogpostPreviewData | null,
    IpfsFiles: IIpfsFiles[]
  ) {
    const response = await api.put(
      `api/Publication/updateDraft`,
      {
        Text: getEditorStateAsString(text),
        Title: previewData?.title
          ? getEditorStateAsString(previewData.title)
          : '',
        Description: previewData?.description
          ? getEditorStateAsString(previewData.description)
          : '',
        PreviewLink: previewData?.preview ? previewData.preview : '',
        IpfsFiles: CreatePostStore.convertIpfsFiles(IpfsFiles, false),
      },
      {
        params: { id },
      }
    )

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async deleteDraft(id: string) {
    const response = await api.delete(`api/Publication/deleteDraft`, {
      params: { id },
    })

    if (response.status !== 204) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async pinPublication(publicationId: number) {
    const response = await api.put(`api/Publication/pinPublication`, null, {
      params: {
        id: publicationId,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }
}

export default new PublicationStore()
